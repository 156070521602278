.list-item {
    display: flex;
    align-items: center;
    position: relative;
}

.match-time {
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 3px;
}
