.panel {
    position: relative;
    padding-top: 50px;
}

.head {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 50px;
    display: flex;
    align-items: center;
}

.head .title {
    position: absolute;
    left: 0;
    right: 0;
    text-align: center;
    font-weight: 600;
    font-size: 15px;
    z-index: 1;
}

.head .bp3-button {
    position: absolute;
    left: 5px;
    z-index: 2;
}

.splash {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: #fafafa;
    visibility: hidden;
    opacity: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all .6s ease-in-out;
    z-index: 10;
}

.splash._active {
    visibility: visible;
    opacity: 1;
}

.splash .brand {
    width: 110px;
    height: 110px;
    box-sizing: border-box;
    border-radius: 11px;
    overflow: hidden;
}

.splash .brand img {
    width: 100%;
}

.panel .screen, .screen.column {
    display: flex;
    height: calc(100vh - 50px);
    align-items: center;
    justify-content: center;
    background: #faf8fa;
    flex-wrap: wrap;
    overflow: auto;
    overflow-x: hidden;
}

.screen.list {
    flex-direction: column;
    box-sizing: border-box;
    padding: 6px;
    position: relative;
    justify-content: flex-start;
}

.screen.column {
    flex-direction: column;
    flex-wrap: nowrap;
    position: relative;
}

.panel .screen-column.aligned-top {
    align-items: flex-start;
}

.list .list-segment {
    width: 100%;
}

.list-segment_title {
    font-weight: 500;
    margin-top: 12px;
}

.screen .tooltop {
    white-space: nowrap;
    padding: 15px 10px 0;
    transition: all .4s ease-in-out;
}

.screen .tooltop .bp3-button {
    width: 100px;
    margin: 0 5px;
}

/* .screen.column > *:not(.cancel-action) {
    width: 100%;
    text-align: center;
} */

.screen.list .bp3-card.list-item, .bp3-card.content-card {
    margin-top: 12px;
    box-shadow: rgba(80, 143, 214, 0.3) 0px 10px 50px;
    width: 100%;
    padding: 8px;
}

.bp3-card.content-card {
    margin-top: 0;
}

.screen .bp3-tabs {
    padding: 0;
}

.list .list-footer {
    position: fixed;
    background: #fff;
    bottom: 0;
    right: 0;
    left: 0;
    height: 50px;
    box-shadow: rgba(80, 143, 214, 0.07) 0px 10px 50px;
    padding: 6px;
}

.list .list-footer.xl {
    height: 62px;
}

.list .list-footer .bp3-button {
    height: 38px;
}

.bp3-card.panel-icon {
    width: 68px;
    height: 68px;
    border-radius: 4px;
    box-sizing: border-box;
    padding: 4px;
    box-shadow: rgba(80, 143, 214, 0.3) 0px 10px 50px;
}

.bp3-card.panel-icon img {
    width: 100%;
}

.panel-icon-wrap {
    width: 68px;
    margin: 0 10px 10px;
}

.panel-icon-wrap label {
    display: block;
    margin-top: 4px;
    font-weight: 600;
    white-space: nowrap;
    font-size: 13px;
    text-align: center;
    overflow: hidden;
    text-overflow: clip;
}

.panel-modal {
    position: fixed;
    top: 56px;
    bottom: 56px;
    left: 6px;
    right: 6px;
    background: #fff;
    box-shadow: rgba(80, 143, 214, 0.3) 0px 10px 30px;
    padding: 12px;
    border-radius: 6px;
}

.panel-modal > * {
    margin-bottom: 12px;
}

.switches-group {
    padding: 10px 4px;
}

.switches-group > * {
    margin-bottom: 20px !important;
}

.panel-modal .code {
    font-size: 54px;
    text-align: center;
    padding-top: 30px;
    letter-spacing: 13px;
}

.list-item_avatar > div {
    background: #ffc107;
    width: 44px;
    height: 44px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 17px;
    font-weight: 600;
}

.list-item_body {
    padding-left: 12px;
    display: flex;
    justify-content: space-between;
    width: calc(100% - 44px);
}

.inner-grid-group {
    width: 100%;
    margin-top: 14px;
}

.inner-grid-group .title {
    padding-left: 10px;
    font-weight: 800;
    padding-bottom: 6px;
}

.inner-grid {
    display: flex;
    flex-wrap: wrap;
}

.panel-icon.bgd {
    background-size: 100px;
    background-repeat: no-repeat;
    background-position: center;
}

.screen.list.clubs {
    padding: 0;
}

.cancel-action {
    position: absolute;
    right: 15px;
    top: 15px;
}

.cancel-action svg {
    width: 24px;
    height: 24px;
}

.screen.list .bp3-input-group {
    margin-bottom: 12px;
}

.bp3-control-group .bp3-input-group {
    width: 50%;
}

.screen-image {
    margin: 20px 0;
}

.screen-image img {
    border-radius: 40px;
    width: 120px;
}

.screen-title {
    margin: 0px 0 20px;
    font-weight: 800;
    font-size: 20px;
}

.bp3-intent-default {
    background-image: linear-gradient(109.6deg,  rgba(255,219,47,1) 11.2%, rgba(244,253,0,1) 100.2% );
}

.bp3-button.bp3-large.xl {
    height: 50px;
    font-weight: 800;
}

.bp3-intent-default:disabled {
    opacity: .2;
}

.slidable-up {
    transition: all .4s ease-in-out;
    margin-top: 20px;
}

.bp3-card.action-view {
    justify-content: space-between;
    padding: 12px;
}

.action-view .header {
    text-align: center;
    padding-top: 20px;
}

.action-view .action-subject {
    font-weight: 500;
    padding-top: 8px;
    font-size: 18px;
}

.action-view .transfer-view {
    text-align: center;
    position: relative;
}

.action-view .transfer-view::before {
    display: block;
    content: '';
    top: 40px;
    left: 50%;
    z-index: 0;
    bottom: 70px;
    width: 1px;
    background: #ccc;
    position: absolute;
}

.transfer-view img {
    width: 80px;
    border-radius: 20px;
    border: 1px solid #ccc;
}

.transfer-view img.player-img {
    width: 60px;
}

.transfer-view > div {
    margin-bottom: 50px;
    position: relative;
}

.transfer-view > div:last-child {
    margin-bottom: 0;
}

.bottom-panel {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 12px;
    background: #fff;
    height: 194px;
    transform: translateY(130px);
    transition: all .25s ease-in-out;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-shadow: rgba(80, 143, 214, 0.65) 0px 10px 50px;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
}

.bottom-panel.opened {
    transform: translateY(0);
}

.bottom-panel .radio-group {
    padding-left: 40px;
}

.screen .matches {
    height: calc(100vh - 95px);
    width: 100%;
    overflow-y: scroll;
    padding: 24px 12px;
    overflow-x: hidden;
}

.screen .matches.item {
    height: calc(100vh - 50px);
}

.screen .matches .bp3-card {
    margin-bottom: 12px;
    box-shadow: rgba(80, 143, 214, 0.3) 0px 10px 50px;
}

.bp3-card.match-card {
    padding: 0px 12px;
}

.match-card .row {
    height: 34px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.match-card .row:first-child {
    border-bottom: 1px solid #faf8fa;
}

.match-card .club {
    display: flex;
    align-items: center;
    font-weight: 500;
    width: calc(100% - 140px);
    flex-wrap: nowrap;
    justify-content: flex-start;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.match-card .club > div {
    width: 20px;
    height: 20px;
    border-radius: 3px;
    background-position: center;
    background-size: 15px;
    background-repeat: no-repeat;
    margin-right: 8px;
    border: 1px solid #eee;
}

.match-card .status {
    width: 80px;
    white-space: nowrap;
}

.match-card .score {
    width: 30px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.match-card .score > div {
    width: 20px;
    height: 20px;
    border-radius: 3px;
    border: 1px solid #faf8fa;
    font-weight: 700;
    text-align: center;
}

.matches .sheet-view {
    height: 45vh;
    margin: -22px -12px 0;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.matches .sheet-view.events {
    height: 60vh;
    background-size: cover;
    background-position: center -30vh;
}

.matches.item .evt {
    display: flex;
    margin-top: 3px;
    align-items: center;
    border-bottom: 1px solid #eee;
    padding-bottom: 3px;
}

.matches.item .evt .icon {
    width: 34px;
    height: 24px;
    background-size: 18px;
    background-position: center center;
    background-repeat: no-repeat;
}

.matches.item .evt .icon.goal {
    background-image: url('../../assets/color/football.svg');
}

.matches.item .evt .icon.goal.owngoal {
    background-image: url('../../assets/color/ownball.png');
}

.matches.item .evt .icon.yc {
    background-image: url('../../assets/color/yellowcard.png');
}

.matches.item .evt .icon.rc {
    background-image: url('../../assets/color/redcard.png');
}

.matches.item .evt > div:first-child {
    width: 20px;
    text-align: right;
}

.matches.item .evt > div:last-child {
    padding-left: 5px;
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.matches.item .evt > div:last-child .bp3-button, .squad-row .bp3-button {
    position: absolute;
    right: 0;
    bottom: 3px;
}

.matches.item .evt > div:last-child i {
    display: block;
    font-size: 11px;
}

.matches.item .squad-row {
    height: 32px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #eee;
    position: relative;
}

.matches.item .squad-row b {
    margin-right: 13px;
    width: 20px;
    text-align: right;
}

.btns-lock {
    position: fixed;
    top: 50px;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10;
    background: transparent;
}

@media screen and (max-width: 601px) and (min-aspect-ratio: 10/11) {
    .slidable-up {
        margin-top: -150px;
    }
}
