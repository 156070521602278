.workspace-list {
    position: relative;
    background: #ecf5fd;
    padding: 7px;
}

.workspace.nested .workspace-list {
    height: calc(100vh - 50px);
}

.workspace-list_add {
    position: absolute;
    bottom: 10px;
    left: 10px;
}

.workspace-list_item {
    padding: 4px;
    display: flex;
    align-items: center;
    transition: all .4s ease-in-out;
    margin-bottom: 7px;
}

.bp3-card.workspace-list_item {
    padding: 3px 12px;
}

.workspace-list_item.active {
    background: #0d88ff;
    color: #fff;
}

.workspace-list_item:hover {
    cursor: pointer;
    opacity: .75;
    background: #f8f8f8;
}

.workspace-list_item.active:hover {
    cursor: default;
    opacity: 1;
    background: #0d88ff;
}

.workspace-list_item img {
    width: 42px;
    margin-right: 8px;
}

.overlay-form {
    width: 640px;
    position: relative;
    margin: 200px auto;
    z-index: 9999;
    padding: 0 !important;
}

.overlay-form .form {
    padding: 12px;
}

.form {
    padding-bottom: 12px;
}

.workspace.nested .form {
    padding-left: 12px;
}

.overlay-form_actions {
    display: flex;
    justify-content: space-around;
    border-top: 1px solid #eee;
    padding: 7px 12px;
}

.form .bp3-control-group {
    margin-bottom: 12px;
}

.form .bp3-control-group:last-child {
    margin-bottom: 0;
}

.dialog {

}

.dialog-popover_item {
    width: 490px;
    box-sizing: border-box;
    padding: 4px 9px;
    z-index: 9999;
}

.dialog-popover_item:hover {
    cursor: pointer;
    background: #eee;
}

.panels-list {
    padding-top: 14px;
    display: flex;
    flex-wrap: wrap;
    padding-right: 14px;
}

.panels-list_title {
    font-weight: 700;
    font-size: 18px;
    margin-bottom: 8px;
    width: 100%;
    margin-top: 20px;
}

.panels-list .bp3-card {
    min-width: 160px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    font-size: 13px;
    font-weight: 500;
    text-align: center;
    flex-direction: column;
}

.panels-list .bp3-card span {
    opacity: .92;
}

.section {
    padding: 12px;
}

.section-title {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 12px;
}

.panels-list.season-clubs {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-gap: 6px;
}
