.moderate {
    display: flex;
    flex-wrap: wrap;
    padding-left: 50px;
}

/* .moderate-actions, .score-form {
    padding: 12px !important;
    width: 320px;
} */

.moderate-actions {
    width: 440px;
    position: fixed;
    top: 10px;
    left: 60px;
    padding: 8px !important;
}

.score-form {
    padding: 6px !important;
    margin-bottom: 10px;
    justify-content: space-between;
}

.moderate-actions > * {
    margin: 5px;
}

.workspace-drawer-trigger {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: 50px;
    transition: all .4s ease-in-out;
    z-index: 20;
}

.workspace-drawer-trigger.bp3-intent-danger {
    background: rgba(219, 55, 55, 0.09);
}

.workspace-drawer-trigger.bp3-intent-danger:hover {
    background: rgba(219, 55, 55, 0.15);
}

.workspace-drawer-trigger.bp3-intent-success {
    background: rgba(15, 153, 96, 0.09);
}

.workspace-drawer-trigger.bp3-intent-success:hover {
    background: rgba(15, 153, 96, 0.15);
}

.workspace-drawer-trigger .bp3-button {
    width: 100vh;
    height: 50px;
    transform: rotate(270deg);
    transform-origin: 100% 0%;
    margin-left: -100vh;
    transition: all .4s ease-in-out;
}

.origin {
    width: 750px;
    text-align: right;
}

.origin img {
    max-width: 100%;
    transition: all .4s ease-in-out;
    position: sticky;
    top: 10px;
}

.origin img:hover {
    opacity: .92;
    cursor: zoom-in;
}

.moderate .bp3-tab-panel > div {
    display: flex;
}

.moderate .bp3-tab-panel .controls {
    width: 640px;
    box-sizing: border-box;
    padding-left: 18px;
}

.squads {
    display: flex;
    box-sizing: border-box;
    padding-left: 0;
    justify-content: space-between;
}

.squad-team {
    border-radius: 4px;
    border: 1px solid #ccc;
    width: 49%;
}

.squad-team_item {
    display: flex;
    align-items: center;
    height: 40px;
    border-bottom: 1px solid #eee;
    padding: 0 5px;
}

.squad-team_item.duplicated {
    background: rgba(255, 0, 0, .1);
}

.squad-team_item_name {
    padding-left: 10px;
    transition: all .4s ease-in-out;
}

.squad-team_item_name.muted {
    opacity: .5;
}

.events-form {
    margin: 0px 10px 10px 0;
    width: 410px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.events-form > * {
    min-height: 40px;
    border-bottom: 1px solid #eee;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: 3px 5px;
}

.events-form label {
    font-weight: 700;
}

.event-form_item .bp3-tag {
    margin-top: 3px;
    max-width: 99px;
    width: 99px;
    margin-right: 3px;
}

.squad-team span.bp3-popover-target, span.bp3-popover-wrapper {
    display: block;
}

.score-form {
    display: flex;
}

.moderate .bp3-tab-list {
    padding-left: 770px;
    padding-top: 20px;
}

.squad-team {
    border-top: none;
}

.bp3-card.player-suggest {
    padding: 0;
    margin-bottom: 12px;
}

.bp3-card.mod-snippet {
    margin: 6px 6px 9px;
    padding: 10px;
    padding-bottom: 6px;
    overflow: visible;
}

.mod-snippet .teams {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 500;
}

.workspace-list.unpadded {
    padding: 0;
}

.workspace-list.unpadded .bp3-tabs {
    padding-left: 0;
}

.workspace-list.unpadded .bp3-tab-panel {
    margin-top: 8px;
}

.workspace-list.unpadded .bp3-tab-list {
    justify-content: center;
}

.workspace-list .bp3-non-ideal-state {
    height: 350px;
}

.mod-snippet .teams img {
    height: 24px;
    width: 24px;
    opacity: .6;
    transition: all .4s linear;
}

.mod-snippet:hover .teams img {
    opacity: 1;
}

.mod-snippet .teams > div:not(.bp3-icon) {
    width: 45%;
    font-size: 13px;
    display: flex;
    align-items: center;
}

.mod-snippet .teams > div:not(.bp3-icon) > span {
    display: block;
    max-width: calc(100% - 28px);
    white-space: nowrap;
    overflow: hidden;
}

.mod-snippet .teams > div:first-child {
    justify-content: flex-end;
}

.mod-snippet .teams > div:first-child img {
    margin-left: 4px;
}

.mod-snippet .teams > div:last-child img {
    margin-right: 4px;
}

.mod-snippet .tags {
    margin: -14px -12px 5px;
    text-align: right;
}

.mod-snippet .tags .bp3-tag {
    font-size: 10px;
    font-weight: 500;
    height: 13px;
    padding: 1px 4px 0px;
    margin-left: 6px;
}

.mod-snippet .info {
    padding: 7px 0 0;
    margin-top: 5px;
    border-top: 1px solid #eee;
    display: flex;
    flex-wrap: wrap;
    opacity: .72;
}

.mod-snippet .info > div {
    width: 50%;
    font-size: 10px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    padding-right: 7px;
    box-sizing: border-box;
}

.mod-snippet .info > div > div {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

@media screen and (max-width: 1200px) {
    .origin {
        width: 600px;
    }

    .moderate .bp3-tab-panel .controls {
        width: 530px;
    }

    .squads .bp3-numeric-input input.bp3-input {
        width: 42px;
    }

    .moderate-actions {
        width: 610px;
    }

    .moderate-actions .bp3-button {
        max-width: 200px;
        white-space: nowrap;
        text-overflow: ellipsis;
        text-align: left;
        overflow: hidden;
    }

    .squad-team_item .bp3-numeric-input .bp3-button-group.bp3-fixed {
        display: none;
    }

    .moderate .bp3-tab-list {
        padding-left: 750px;
    }
}
