.match-dialog {
    width: 410px;
}

.match-dialog .bp3-non-ideal-state {
    min-height: 320px;
}

.title {
    position: relative;
}

.title .bp3-spinner {
    position: absolute;
    right: 0;
    top: 0;
}

.title .bp3-tag {
    margin-left: 4px;
    margin-top: -6px;
    position: absolute;
}

.dialog-item {
    background: #f2f9ff;
    padding: 6px 11px;
    border-radius: 5px;
    margin-top: 11px;
}

.dialog-item._applied {
    background: #effff4;
    opacity: .75;
}

.dialog-item_top {
    font-size: 13px;
    font-weight: 500;
    padding-top: 3px;
}

.dialog-item_details {
    border-radius: 6px;
    padding: 5px 9px;
    background: #dcecfb;
    margin-top: 4px;
    font-size: 14px;
}

.dialog-item._applied .dialog-item_details {
    background: #d5f3e0;
}

.dialog-item_reply {
    margin-top: 6px;
    display: flex;
    font-size: 14px;
    width: 100%;
    box-sizing: border-box;
    padding: 0 9px 0 0;
}

.dialog-item_reply_actions {
    width: 50%;
    display: flex;
    align-items: center;
}

.dialog-item_reply_content {
    text-align: right;
    width: 50%;
}

.dialog-item_reply_actions .bp3-button {
    font-size: 13px;
    height: 26px;
    min-height: 26px;
    margin-right: 3px;
}
