#orders {
    grid-area: orders;
}

#clubs {
    grid-area: clubs;
}

#graphs {
    grid-area: graphs;
}

#content {
    grid-area: content;
}

#agents {
    grid-area: agents;
}

#leagues {
    grid-area: leagues;
}

#break {
    grid-area: break;
}

#stats {
    padding: 12px;
    display: grid;
    grid-gap: 12px;
    height: 100vh;
    background: #f8faf8;
    grid-template-areas:
        "orders content content agents agents"
        "orders content content agents agents"
        "orders content content break break"
        "graphs content content leagues clubs"
        "graphs content content leagues clubs"
}

#stats > div > .bp3-card {
    height: 100%;
}

#stats .incard {
    padding: 12px;
    background: #faf8f8;
    border-radius: 5px;
}

#stats .incard .bp3-control-group {
    max-width: 400px;
}
