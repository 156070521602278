.logs {
    padding: 20px;
}

.bp3-card.logs-item {
    padding: 7px 10px;
    margin-bottom: 6px;
    display: flex;
    align-items: center;
}
