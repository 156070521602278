.match-body {
    padding: 12px 8px;
}

.match-body_row {
    display: flex;
}

.match-body_row .filter-box {
    box-sizing: border-box;
    padding: 0 4px;
}

.filter-box.actions > div {
    margin-bottom: 12px;
}
