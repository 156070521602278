@font-face {
    font-family: 'GothamPro';
    src: url('./assets/fonts/GothamPro.ttf') format('truetype');
    font-weight: normal;
}

@font-face {
    font-family: 'GothamPro';
    src: url('./assets/fonts/GothamPro-Medium.ttf') format('truetype');
    font-weight: 500;
}

@font-face {
    font-family: 'GothamPro';
    src: url('./assets/fonts/GothamPro-Bold.ttf') format('truetype');
    font-weight: 700;
}

@font-face {
    font-family: 'GothamPro';
    src: url('./assets/fonts/GothamPro-Black.ttf') format('truetype');
    font-weight: 900;
}

body {
    margin: 0;
    font-family: 'GothamPro', sans-serif !important;
    background: #f6fbff;
}

.bp3-tabs {
    padding-left: 10px;
}

.bp3-numeric-input .bp3-button-group.bp3-fixed {
    z-index: 99;
}

.bp3-numeric-input input.bp3-input {
    width: 50px;
}

.event-form_item .bp3-numeric-input input.bp3-input {
    width: 76px;
}

.bp3-input-group .bp3-input:not(:first-child) {
    padding-left: 26px;
}

.bp3-card.bp3-elevation-2 {
    box-shadow: 0 2px 3px 0 rgba(39, 106, 158, 0.07), 0 10px 15px 0 rgba(21, 21, 21, .04)
}

.bp3-card.bp3-interactive:hover {
    box-shadow: 1px 2px 3px 0 rgba(39, 106, 158, 0.15), 0 10px 15px 0 rgba(21, 21, 21, .12) !important;
}

.workspace {
    display: flex;
}

.workspace-list {
    width: 300px;
    height: 100vh;
    box-sizing: border-box;
    position: relative;
}

.workspace-list.wstatus {
    padding-top: 40px;
}

.workspace-list.wstatus .status-stick {
    position: absolute;
    left: 5px;
    top: 5px;
}

.match-snippet {
    display: flex;
    align-items: center;
    border-top: 1px solid #eee;
    padding: 3px 6px;
    box-sizing: border-box;
    transition: all .4s ease-in-out;
}

.match-snippet:hover {
    cursor: pointer;
    opacity: .72;
    background: #f8f8f8;
}

.match-snippet:last-child {
    border-bottom: 1px solid #eee;
}

.match-snippet img {
    width: 52px;
}

.match-snippet_info {
    padding-left: 4px;
    box-sizing: border-box;
    overflow: hidden;
}

.match-snippet_teams {
    font-weight: 600;
    padding-bottom: 2px;
}

.match-snippet_tags {
    display: flex;
    flex-wrap: wrap
}

.match-snippet_tags > * {
    margin-right: 4px;
}

.popover-body {
    padding: 8px;
    width: 230px;
    z-index: 99999;
}

.list-view {
    padding: 12px;
}

.list-view .title {
    font-weight: 800;
    margin: 12px 0 21px;
    font-size: 16px;
    text-align: center;
    position: relative;
}

.list-view .title .bp3-button {
    position: absolute;
    left: -12px;
    top: -5px;
}

.list-view .bp3-card {
    margin-bottom: 9px;
    padding: 12px;
    position: relative;
    padding-left: 74px;
    height: 56px;
    display: flex;
    align-items: center;
}

.list-item_img {
    width: 56px;
    height: 56px;
    opacity: .8;
    position: absolute;
    left: 0;
    top: 0;
    background-size: 180%;
    background-position: center center;
}

.list-item_main {
    font-size: 16px;
    font-weight: 500;
}
