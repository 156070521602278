.moder {
    padding-left: 60px;
    height: 100vh;
    width: 100vw;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    overflow: hidden;
}

.bp3-card.moder-actions {
    position: fixed;
    top: 20px;
    right: 20px;
    border-radius: 20px;
    padding: 14px 20px;
}

.bp3-card.moder-actions .bp3-button {
    padding: 5px 15px;
    border-radius: 40px;
}

.moder-errs {
    width: 200px;
    position: fixed;
    left: 70px;
    top: 20px;
}

.moder-errs .bp3-callout {
    margin-bottom: 8px;
    border-radius: 12px;
}

.moder-head {
    text-align: center;
    display: flex;
    flex-wrap: wrap;
}

.moder-head h1 {
    width: 100%;
}

.moder-head_info {
    display: flex;
    justify-content: center;
    width: 100%;
}

.moder-head_info > div {
    margin: 0 10px;
    font-weight: 300;
    font-size: 14px;
}

.stepper {
    width: 100%;
    margin-top: 25px;
}

.stepper-top {
    display: flex;
    justify-content: center;
    width: 100%;
    align-items: center;
}

.stepper-top > div {
    margin: 0 30px;
    text-transform: uppercase;
    font-weight: 300;
    font-size: 16px;
    position: relative;
    padding: 8px 14px 3px;
    border-radius: 11px;
    transition: all .4s ease-in-out;
}

.stepper-top > div:not(.current):hover {
    cursor: pointer;
    background: #ebeff3;
}

.stepper-top > div::before {
    content: '';
    position: absolute;
    height: 2px;
    width: 48px;
    right: -54px;
    background: #b9b9b9;
    top: 15px;
}

.stepper-top > div:last-child:before {
    display: none;
}

.stepper-top > div.current {
    background: #0d88ff;
    color: #fff;
    box-shadow: 0px 1px 2px 0px rgb(19 134 250), 0px 2px 10px 0px rgb(126 189 251);
}

.step-wrap {
    opacity: 0;
    visibility: hidden;
    transition: all .25s linear;
    height: 0;
    box-sizing: border-box;
}

.step-wrap.shown {
    opacity: 1;
    visibility: visible;
    height: calc(100vh - 200px);
    padding: 30px;
}

.bp3-card.cropper-wrap {
    margin: 0 auto;
    position: relative;
    box-sizing: border-box;
    padding: 10px;
}

.cropper-wrap .origin {
    width: 100%;
}

.cropper-wrap.sq {
    width: 700px;
}

.cropper-wrap.evs {
    width: 900px;
}

.cropper-wrap .rotate-btn {
    position: absolute;
    top: 0;
    right: -50px;
    border-radius: 50%;
}

.cropper-wrap .rotate-btn:focus {
    outline: none;
}

.cropper-action {
    position: absolute;
    z-index: 300;
    background: rgba(255, 255, 255, .85);
    width: 158px;
    height: 40px;
    border-radius: 40px;
    display: flex;
    align-items: center;
    font-weight: 500;
    transition: all .4s ease-in-out;
}

.cropper-action:hover {
    cursor: pointer;
    opacity: .91;
}

.cropper-action img {
    width: 40px;
    border-radius: 50%;
    margin-right: 8px;
}

.step-content .bp3-non-ideal-state {
    margin-top: 50px;
}

.step-squad {
    margin: 20px auto 0;
    display: flex;
    position: relative;
    width: 780px;
    flex-wrap: wrap;
}

.step-squad_col {
    width: 33.3%;
    box-sizing: border-box;
    padding-left: 18px;
}

.step-squad_col:first-child {
    padding-left: 0;
}

.step-squad_item {
    display: flex;
    align-items: center;
    height: 40px;
    margin-bottom: 8px;
}

.step-squad_item input, .events-section_item input, .moder-head_score input, .step-squad_actions input, .squad-lines input {
    width: 44px;
    border: none;
    border-bottom: 2px solid #d7e9f7;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15), 0px 2px 10px 0px rgba(0, 0, 0, 0.18);
    border-radius: 6px;
    margin-right: 14px;
    height: 40px;
    transition: all .2s linear;
    text-align: center;
    font-size: 22px;
}

.step-squad_item input:focus, .events-section_item input:focus, .moder-head_score input:focus, .step-squad_actions input:focus {
    outline: none;
}

.step-squad_item input:hover, .events-section_item input:hover, .moder-head_score input:hover, .step-squad_actions input:hover, .squad-lines input:hover {
    background: #edf5fb;
    cursor: pointer;
}

.step-squad_item > div {
    font-size: 16px;
    display: block;
    white-space: nowrap;
    position: relative;
}

.step-squad_item input[type='number'], .events-section_item input[type='number'], .moder-head_score input[type='number'], .step-squad_actions input[type='number'], .squad-lines input[type='number'] {
    -moz-appearance:textfield;
}

.step-squad_item input::-webkit-outer-spin-button,
.step-squad_item input::-webkit-inner-spin-button,
.events-section_item input::-webkit-outer-spin-button,
.events-section_item input::-webkit-inner-spin-button,
.moder-head_score input::-webkit-outer-spin-button,
.moder-head_score input::-webkit-inner-spin-button,
.step-squad_actions input::-webkit-outer-spin-button,
.step-squad_actions input::-webkit-inner-spin-button,
.squad-lines input::-webkit-outer-spin-button,
.squad-lines input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

.step-squad_item input.warning, .events-section_item input.warning, .squad-lines input.warning {
    background: #fff2cc;
}

.step-squad_item input.danger, .events-section_item input.danger, .squad-lines input.danger {
    background: #ffd0c1;
}

.squad-lines input.overflow {
    background: #ffffc1;
}

.squad-lines input.overflow.warning{
    background: #ff7800;
}

.step-squad_item > div .toggler {
    opacity: 0;
    visibility: hidden;
    transition: all .3s ease-in-out;
    position: absolute;
    left: 0px;
    font-weight: 500;
    padding: 7px 17px 5px 14px;
    border-radius: 15px;
    top: -7px;
}

.step-squad_item > div .toggler.off {
    background: #f00;
    color: #fff;
}

.step-squad_item > div .toggler.on {
    background: #4CAF51;
    color: #fff;
}

.step-squad_item > div:hover .toggler {
    opacity: 0.88;
    visibility: visible;
    cursor: pointer;
}

.step-squad_item > div:hover .toggler .bp3-icon {
    margin-right: 8px;
}

.step-squad_item.absent input {
    background: #e2e2e2;
}

.step-squad_item.absent > div {
    color: #7d7d7d;
}

.step-squad_actions {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 0 0 25px;
}

.step-squad_actions .bp3-button {
    border-radius: 20px;
    margin: 0 10px;
}

.step-squad_actions .bp3-button:focus {
    outline: none;
}

.step-events {
    display: flex;
    justify-content: center;
}

.step-events .img {
    display: flex;
    flex-direction: column;
}

.step-events .content {
    margin-left: 20px;
    width: 480px;
}

.bp3-card.events-section {
    margin-bottom: 14px;
    padding: 11px 0 0;
}

.events-section_title {
    font-weight: bold;
    border-bottom: 1px solid #eee;
    padding: 0 9px 11px;
    font-size: 16px;
    text-align: left;
}

.events-section_body {
}

.events-section_item:nth-child(odd) {
    background: #f8f8fe;
}

.events-section_item {
    display: flex;
    padding: 2px 40px 7px 7px;
    position: relative;
}

.events-section_item > div {
    display: flex;
    flex-direction: column;
    text-align: center;
}

.events-section_item > div b {
    font-size: 12px;
}

.events-section_item input {
    margin-right: 0;
}

.events-section_item input:focus {
    background: #e3f2ff;
}

.events-section_item .bp3-tag {
    font-size: 10px;
    overflow: hidden;
    line-height: 12px;
}

.events-section_item .boxes {
    align-items: flex-start;
    justify-content: space-between;
    padding-left: 12px;
    padding-top: 4px;
}

.events-section_item .boxes .bp3-control {
    margin-bottom: 2px;
    font-size: 12px;
    font-weight: 500;
}

.events-section_item .del {
    position: absolute;
    right: 5px;
    top: 15px;
}

.moder-head_score {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-top: 18px;
}

.moder-head_score > div {
    width: 260px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.moder-head_score > div > * {
    margin: 0;
}

.moder-head_score .bp3-control {
    margin-bottom: 0px;
}

.match-dialog {
    position: fixed;
    bottom: 10px;
    right: 10px;
    width: 350px;
    transition: all .3s linear;
}

.match-dialog.slidden {
    transform: translateY(calc(100vh - 440px));
}

.match-dialog.slidden:hover {
    cursor: pointer;
    transform: translateY(calc(100vh - 460px));
}

.match-dialog .bp3-card {
    border-radius: 18px;
}

.stat-popover-cell > div {
    width: 100px;
    font-size: 12px;
    color: #787878;
}

.stat-popover-cell > div b {
    display: block;
    color: #252525;
}
.squad-lines {
    display: flex;
    margin-top: 20px;
}
.squad-lines > div {
    display: flex;
    align-items: center;
}
