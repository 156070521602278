.space {
    height: 100vh;
    display: flex;
}

.side {
    height: 100vh;
    width: 320px;
    background: #f8f8f8;
    box-sizing: border-box;
    padding: 8px;
}

.body {
    width: calc(100% - 900px);
    height: 100vh;
    overflow-y: scroll;
    box-sizing: border-box;
    padding: 12px;
}

.item {
    width: 580px;
}

.filter-box {
    margin-bottom: 12px;
}

.filter-box label {
    font-weight: 700;
    display: block;
    margin-bottom: 4px;
}

.filter-box .bp3-card {
    padding: 8px;
}

.filter-box .bp3-popover-wrapper, .filter-box .bp3-popover-target {
    display: block;
    width: 100%;
}

.body .bp3-card.list-item {
    margin-bottom: 12px;
    padding: 12px;
}

.item {
    height: 100vh;
    background: #fafafa;
}
