/*BLUEPRINT OVERRIDES*/
.bp3-tab:focus {
    outline: none;
}

.bp3-button {
    border-radius: 6px;
    border: none;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1), 0px 2px 10px 0px rgba(0, 0, 0, 0.08) !important;
    transition: all .4s ease-in-out;
}

.bp3-button:hover {
    box-shadow: 3px 3px 0 rgba(0, 0, 0, 0.06), 0 0 0 1px rgba(0, 0, 0, 0.06) !important;
}

.bp3-button.bp3-intent-primary {
    background: rgba(0, 153, 255);
}

.bp3-button.bp3-intent-primary:hover {
    background: rgba(0, 153, 255, .88);
}

.bp3-button.bp3-large {
    min-height: 34px;
}

.bp3-button.bp3-intent-default {
    color: #272727;
    background: #faf8fa;
}

.bp3-button.bp3-intent-lineal {
    background: none;
    color: #fff;
    box-shadow: none !important;
    border: 2px solid #fff;
}

.bp3-input-group.bp3-large .bp3-input:focus {
    outline: none;
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, .03),0 10px 15px 0 rgba(21, 21, 21, .02);
}
/*BLUEPRINT OVERRIDES*/

/*LEGACY FROM AM_APP*/
.bp3-card {
    background: #fff;
    box-sizing: border-box;
    padding: 14px;
    border-radius: 5px;
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, .03),0 10px 15px 0 rgba(21, 21, 21, .02);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.bp3-card.list-item, .bp3-card.row {
    flex-direction: row;
}

.bp3-card .title {
    font-weight: 500;
}

.bp3-card .controls {
    display: flex;
    flex-wrap: wrap;
    padding-left: 50px;
}
.bp3-card .section {
    margin: 12px 0 0;
    padding: 12px;
    border-top: 1px solid #faf8fa;
}

.bp3-card .section .section-title {
    font-weight: 500;
    font-size: 13px;
    margin-bottom: 4px;
}

.bp3-card.social {
    overflow: hidden;
    transition: all .3s linear;
}

.bp3-card.bp3-interactive {
    transition: all .4s ease-in-out;
}

.bp3-card.bp3-interactive:hover {
    cursor: pointer;
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, .08),0 10px 15px 0 rgba(21, 21, 21, .06);
}

/*LEGACY FROM AM_APP*/
.container {
    position: relative;
    max-width: 980px;
    margin: 0 auto;
}

.container-top {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
}

.container-top .bp3-button {
    margin-left: 12px;
    height: 30px;
}

.auth {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.auth-inner h1 {
    font-weight: 300;
    letter-spacing: 1px;
    font-size: 40px;
}

.auth-inner {
    text-align: center;
    margin-bottom: 20px;
}

.auth-inner input {
    font-size: 54px;
    background: none;
    border: none;
    font-weight: 300 !important;
    letter-spacing: 25px;
    text-align: center;
}

.auth-inner input:focus {
    outline: none;
}
